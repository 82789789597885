import React, { useState, useEffect } from "react";
import db from "./firebase";

function App() {
  const [exercise, setExercise] = useState(null);
  const [newAchievement, setNewAchievement] = useState("");

  const fetchRandomExercise = async () => {
    const snapshot = await db.collection("exercises").get();
    const exercises = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const randomIndex = Math.floor(Math.random() * exercises.length);
    setExercise(exercises[randomIndex]);
  };

  const updateAchievement = async () => {
    if (exercise && newAchievement) {
      const exerciseRef = db.collection("exercises").doc(exercise.id);

      await exerciseRef.update({
        "latest achievement": newAchievement,
      });

      // Reload and display the updated exercise
      const updatedExerciseDoc = await exerciseRef.get();
      const updatedExerciseData = updatedExerciseDoc.data();
      setExercise({ id: updatedExerciseDoc.id, ...updatedExerciseData });

      // Clear the input
      setNewAchievement("");
    }
  };

  useEffect(() => {
    fetchRandomExercise();
  }, []);

  return (
    <div className="App">
      <h1>Random Exercise</h1>
      {exercise ? (
        <>
          <p>Name: {exercise.name}</p>
          <p>Target: {exercise.target}</p>
          <p>Latest Achievement: {exercise["latest achievement"]}</p>
          <input
            type="text"
            placeholder="New Achievement"
            value={newAchievement}
            onChange={(e) => setNewAchievement(e.target.value)}
          />
          <button onClick={updateAchievement}>Update Achievement</button>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default App;
