// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";

// import firebase from "firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCp391SItPPxjFqwSoAmPbRabERJmMqDpQ",
  authDomain: "exercise-d240a.firebaseapp.com",
  projectId: "exercise-d240a",
  storageBucket: "exercise-d240a.appspot.com",
  messagingSenderId: "515815024221",
  appId: "1:515815024221:web:60f05c7e388883554f8fb4",
  measurementId: "G-RY58H1GSLN",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const db = getFirestore(app);

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
